import React from "react"
import '../styles/top-image.scss'

export default function TopImage({ images, title }) {
  return (
    <div className="header">
      {images.map((img, idx) => {
        if (idx <= 0)
          return (
            <div key={`content-${idx}`} className="my-slide content">
              <img src={`${img.path}`} alt="Interieur du restaurant" />
            </div>
          )
          return (<></>)
      })}
      <div className="banner">
        <h1>{title}</h1>
      </div>
    </div>
  )
}
